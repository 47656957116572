/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
export interface Response<T> {
  data: T | null;
  hasErrors: boolean;
  errors: Error[];
}

export interface Error {
  propertyName: string;
  errorMessage: string;
}

export interface AdvancedQueryPage<T> {
  items: T[];
  page: number;
  pageSize: number;
  pageCount: number;
  totalItemCount: number;
}

export class AssetUsageService {
  /**
   *
   */
  static createAssetUsage(
    params: {
      /** requestBody */
      body?: CreateAssetUsageRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AssetUsageDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/asset-usage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAssetUsageHistory(
    params: {
      /**  */
      id: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AssetUsageDetailDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/asset-usage/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/asset-usage/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompileTimeStaticsService {
  /**
   *
   */
  static getAll(options: IRequestOptions = {}): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/compile-time-statics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CustomerSettingsService {
  /**
   *
   */
  static getForCustomer(
    params: {
      /**  */
      customerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CustomerSettingsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-settings/get-for-customer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateCustomerSettingsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CustomerSettingsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/customer-settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DashboardService {
  /**
   *
   */
  static getFinancialInformation(
    params: {
      /**  */
      customerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<FinancialInformationDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboard/financial-information';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStats(
    params: {
      /**  */
      customerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<DashboardStatisticsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboard/statistics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getManagerStats(
    params: {
      /**  */
      userId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<DashboardStatisticsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboard/manager-statistics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFleetExpenses(
    params: {
      /**  */
      customerId?: string;
      /**  */
      expensesStartDate?: string;
      /**  */
      expensesEndDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<FleetExpensesDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboard/fleet-expenses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CustomerId: params['customerId'],
        ExpensesStartDate: params['expensesStartDate'],
        ExpensesEndDate: params['expensesEndDate']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFleetOverview(
    params: {
      /**  */
      customerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<FleetOverviewDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboard/fleet-overview';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CustomerId: params['customerId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getManagerFleetOverview(
    params: {
      /**  */
      request?: GetManagerFleetOverviewRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<FleetOverviewDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/dashboard/manager-fleet-overview';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { request: params['request'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExcelExportService {
  /**
   *
   */
  static getExportSettingsForCustomer(
    params: {
      /**  */
      customerId?: string;
      /**  */
      exportItemType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<ExcelExportSettingDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/excel-export';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { CustomerId: params['customerId'], ExportItemType: params['exportItemType'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static saveCustomerExportColumns(
    params: {
      /** requestBody */
      body?: SaveCustomerExportColumnsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/excel-export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FilesService {
  /**
   *
   */
  static upload(
    params: {
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UploadFileResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/files/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null as any;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class InvoicesService {
  /**
   *
   */
  static getAllMonthly(
    params: {
      /**  */
      searchMonth?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<MonthlyInvoiceDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/get-all-monthly';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        searchMonth: params['searchMonth'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllSupplemental(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<SupplementalInvoiceDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/get-all-supplemental';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInvoicesForCustomer(
    params: {
      /**  */
      customerId?: string;
      /**  */
      dueDateStart?: string;
      /**  */
      dueDateEnd?: string;
      /**  */
      invoiceDateStart?: string;
      /**  */
      invoiceDateEnd?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<InvoiceListingDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/customer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CustomerId: params['customerId'],
        DueDateStart: params['dueDateStart'],
        DueDateEnd: params['dueDateEnd'],
        InvoiceDateStart: params['invoiceDateStart'],
        InvoiceDateEnd: params['invoiceDateEnd'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInvoiceLink(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/get-link/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static viewInvoice(
    params: {
      /**  */
      token: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoices/view/{token}';
      url = url.replace('{token}', params['token'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateInvoiceExportRequest(
    params: {
      /**  */
      filterParamsCustomerId?: string;
      /**  */
      filterParamsDueDateStart?: string;
      /**  */
      filterParamsDueDateEnd?: string;
      /**  */
      filterParamsInvoiceDateStart?: string;
      /**  */
      filterParamsInvoiceDateEnd?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/export';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        'FilterParams.CustomerId': params['filterParamsCustomerId'],
        'FilterParams.DueDateStart': params['filterParamsDueDateStart'],
        'FilterParams.DueDateEnd': params['filterParamsDueDateEnd'],
        'FilterParams.InvoiceDateStart': params['filterParamsInvoiceDateStart'],
        'FilterParams.InvoiceDateEnd': params['filterParamsInvoiceDateEnd']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static upload(
    params: {
      /**  */
      customerId?: string;
      /**  */
      invoiceNumber?: string;
      /**  */
      amount?: number;
      /**  */
      invoiceDate?: string;
      /**  */
      dueDate?: string;
      /**  */
      isSupplemental?: boolean;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<InvoiceGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        CustomerId: params['customerId'],
        InvoiceNumber: params['invoiceNumber'],
        Amount: params['amount'],
        InvoiceDate: params['invoiceDate'],
        DueDate: params['dueDate'],
        IsSupplemental: params['isSupplemental']
      };
      let data = null as any;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generate(
    params: {
      /**  */
      customerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/generate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { CustomerId: params['customerId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateAll(options: IRequestOptions = {}): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/generate-all';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBatchStatus(options: IRequestOptions = {}): Promise<Response<GetCurrentInvoiceGenerationBatchResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/batch-status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOptions(
    params: {
      /**  */
      customerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<OptionItemDto_String>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/invoices/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LeaseCompleteAssetsService {
  /**
   *
   */
  static getAllLeaseCompleteAssetsByCustomerId(
    params: {
      /**  */
      assetRegistrationStatus?: string;
      /**  */
      assetLeaseExpirationStatus?: string;
      /**  */
      assetStatus?: string;
      /**  */
      assetType?: string;
      /**  */
      maintenancePlan?: string;
      /**  */
      includeSold?: boolean;
      /**  */
      customerId?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AssetListingsDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-assets/table';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        AssetRegistrationStatus: params['assetRegistrationStatus'],
        AssetLeaseExpirationStatus: params['assetLeaseExpirationStatus'],
        AssetStatus: params['assetStatus'],
        AssetType: params['assetType'],
        MaintenancePlan: params['maintenancePlan'],
        IncludeSold: params['includeSold'],
        customerId: params['customerId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      assetId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AssetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-assets/{assetId}';
      url = url.replace('{assetId}', params['assetId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAssetExecutedContractLink(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-assets/contract-link/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadAssetExecutedContract(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-assets/contract/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateAssetRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-assets';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateAssetExport(
    params: {
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filterParamsAssetRegistrationStatus?: string;
      /**  */
      filterParamsAssetLeaseExpirationStatus?: string;
      /**  */
      filterParamsAssetStatus?: string;
      /**  */
      filterParamsAssetType?: string;
      /**  */
      filterParamsMaintenancePlan?: string;
      /**  */
      filterParamsIncludeSold?: boolean;
      /**  */
      customerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-assets/export';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        'FilterParams.AssetRegistrationStatus': params['filterParamsAssetRegistrationStatus'],
        'FilterParams.AssetLeaseExpirationStatus': params['filterParamsAssetLeaseExpirationStatus'],
        'FilterParams.AssetStatus': params['filterParamsAssetStatus'],
        'FilterParams.AssetType': params['filterParamsAssetType'],
        'FilterParams.MaintenancePlan': params['filterParamsMaintenancePlan'],
        'FilterParams.IncludeSold': params['filterParamsIncludeSold'],
        CustomerId: params['customerId']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateRegistrationExceptionReport(
    params: {
      /**  */
      customerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-assets/registration-exception-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { customerId: params['customerId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LeaseCompleteBillingHistoryService {
  /**
   *
   */
  static getAssetPaymentHistory(
    params: {
      /**  */
      id: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<BillingHistoryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/billing-history/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTable(
    params: {
      /**  */
      customerId?: string;
      /**  */
      billType?: string;
      /**  */
      dueDateStart?: string;
      /**  */
      dueDateEnd?: string;
      /**  */
      satisfiedDateStart?: string;
      /**  */
      satisfiedDateEnd?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<BillingHistoryListingDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/billing-history/table';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CustomerId: params['customerId'],
        BillType: params['billType'],
        DueDateStart: params['dueDateStart'],
        DueDateEnd: params['dueDateEnd'],
        SatisfiedDateStart: params['satisfiedDateStart'],
        SatisfiedDateEnd: params['satisfiedDateEnd'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFinancialSummary(
    params: {
      /**  */
      customerId?: string;
      /**  */
      billType?: string;
      /**  */
      dueDateStart?: string;
      /**  */
      dueDateEnd?: string;
      /**  */
      satisfiedDateStart?: string;
      /**  */
      satisfiedDateEnd?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BillingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/billing-history/summary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CustomerId: params['customerId'],
        BillType: params['billType'],
        DueDateStart: params['dueDateStart'],
        DueDateEnd: params['dueDateEnd'],
        SatisfiedDateStart: params['satisfiedDateStart'],
        SatisfiedDateEnd: params['satisfiedDateEnd']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateBillingHistoryExport(
    params: {
      /**  */
      filterParamsCustomerId?: string;
      /**  */
      filterParamsBillType?: string;
      /**  */
      filterParamsDueDateStart?: string;
      /**  */
      filterParamsDueDateEnd?: string;
      /**  */
      filterParamsSatisfiedDateStart?: string;
      /**  */
      filterParamsSatisfiedDateEnd?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/billing-history/export';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        'FilterParams.CustomerId': params['filterParamsCustomerId'],
        'FilterParams.BillType': params['filterParamsBillType'],
        'FilterParams.DueDateStart': params['filterParamsDueDateStart'],
        'FilterParams.DueDateEnd': params['filterParamsDueDateEnd'],
        'FilterParams.SatisfiedDateStart': params['filterParamsSatisfiedDateStart'],
        'FilterParams.SatisfiedDateEnd': params['filterParamsSatisfiedDateEnd']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LeaseCompleteCustomerService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<CustListingDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-customers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllForUser(options: IRequestOptions = {}): Promise<Response<List<CustSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-customers/get-all-for-user';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CustDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-customers/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDashboardById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CustDashboardDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lease-complete-customers/dashboard/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LeaseCompletePmServicesService {
  /**
   *
   */
  static getMaintenanceHistory(
    params: {
      /**  */
      assetId: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AssetMaintenanceVisit>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pmservices/visits/{assetId}';
      url = url.replace('{assetId}', params['assetId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMaintenanceVisitItems(
    params: {
      /**  */
      invoiceNumber: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<PmServiceDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/pmservices/table/{invoiceNumber}';
      url = url.replace('{invoiceNumber}', params['invoiceNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LogGroupNotesService {
  /**
   *
   */
  static getByParameters(
    params: {
      /**  */
      parameters: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<LogGroupNotesDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/log-group-notes/{parameters}';
      url = url.replace('{parameters}', params['parameters'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      parameters: string;
      /**  */
      notes?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<LogGroupNotesGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/log-group-notes/{parameters}';
      url = url.replace('{parameters}', params['parameters'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { notes: params['notes'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LogSummariesService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      onlyImportant?: boolean;
      /**  */
      onlyUnresolved?: boolean;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<LogSummaryGroupListItem>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/log-summaries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        OnlyImportant: params['onlyImportant'],
        OnlyUnresolved: params['onlyUnresolved'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<LogSummaryDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/log-summaries/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByGroup(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
      /**  */
      parameters: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<LogSummaryListDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/log-summaries/{parameters}/by-group';
      url = url.replace('{parameters}', params['parameters'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resolveGroup(
    params: {
      /**  */
      parameters: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<LogSummaryGetDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/log-summaries/{parameters}/resolve-group';
      url = url.replace('{parameters}', params['parameters'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateHandledStatus(
    params: {
      /**  */
      id: number;
      /**  */
      status?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<LogSummaryGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/log-summaries/{id}/update-handle-status';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { status: params['status'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PaystarIntegrationService {
  /**
   *
   */
  static initiatePaystarPaymentSession(
    params: {
      /** requestBody */
      body?: InitiatePaystarPaymentSessionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ClientPaymentSessionResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/paystar-integration/initiate-paystar-payment-session';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PaystarWebhooksService {
  /**
   *
   */
  static triggerWebhook(options: IRequestOptions = {}): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/paystar-webhooks';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PdfExportService {
  /**
   *
   */
  static getPdfFields(
    params: {
      /**  */
      pdfTemplate: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<string>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pdf-export/fields';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null as any;
      data = new FormData();
      if (params['pdfTemplate']) {
        if (Object.prototype.toString.call(params['pdfTemplate']) === '[object Array]') {
          for (const item of params['pdfTemplate']) {
            data.append('PdfTemplate', item as any);
          }
        } else {
          data.append('PdfTemplate', params['pdfTemplate'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createExportablePdf(
    params: {
      /**  */
      name?: string;
      /**  */
      filename?: string;
      /**  */
      key?: string;
      /**  */
      exportFields?: ExportablePdfFieldDto[];
      /**  */
      pdfTemplate: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ExportablePdfDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pdf-export';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        Name: params['name'],
        Filename: params['filename'],
        Key: params['key'],
        ExportFields: params['exportFields']
      };
      let data = null as any;
      data = new FormData();
      if (params['pdfTemplate']) {
        if (Object.prototype.toString.call(params['pdfTemplate']) === '[object Array]') {
          for (const item of params['pdfTemplate']) {
            data.append('PdfTemplate', item as any);
          }
        } else {
          data.append('PdfTemplate', params['pdfTemplate'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getExportablePdf(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ExportablePdfDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pdf-export/id';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateExportablePdf(
    params: {
      /**  */
      id: number;
      /**  */
      name?: string;
      /**  */
      filename?: string;
      /**  */
      key?: string;
      /**  */
      exportFields?: ExportablePdfFieldDto[];
      /**  */
      pdfTemplate: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ExportablePdfDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pdf-export/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);
      configs.params = {
        Name: params['name'],
        Filename: params['filename'],
        Key: params['key'],
        ExportFields: params['exportFields']
      };
      let data = null as any;
      data = new FormData();
      if (params['pdfTemplate']) {
        if (Object.prototype.toString.call(params['pdfTemplate']) === '[object Array]') {
          for (const item of params['pdfTemplate']) {
            data.append('PdfTemplate', item as any);
          }
        } else {
          data.append('PdfTemplate', params['pdfTemplate'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteExportablePdf(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pdf-export/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generatePdfExport(
    params: {
      /**  */
      key?: string;
      /**  */
      quoteId?: number;
      /**  */
      assetId?: string;
      /**  */
      customerId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pdf-export/export';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Key: params['key'],
        QuoteId: params['quoteId'],
        AssetId: params['assetId'],
        CustomerId: params['customerId']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPdfExportOptions(options: IRequestOptions = {}): Promise<Response<List<string>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/pdf-export/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QuoteApprovalService {
  /**
   *
   */
  static customerDeny(
    params: {
      /** requestBody */
      body?: CustomerDenyQuoteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-approval/deny';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOptions(
    params: {
      /**  */
      quoteId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<ApprovedQuoteTermDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-approval/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { QuoteId: params['quoteId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static customerApprove(
    params: {
      /** requestBody */
      body?: CustomerApproveQuoteTermRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-approval/approve';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateQuoteManagerApprovalStatus(
    params: {
      /** requestBody */
      body?: UpdateQuoteManagerApprovalStatusRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-approval/update-manager-approval-status';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QuoteAssetsService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateQuoteAssetRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteAssetGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-assets';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateQuoteAssetRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteAssetGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-assets';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteAssetGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-assets/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-assets/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByQuoteId(
    params: {
      /**  */
      id: number;
      /**  */
      includeUnLocatedQuoteAssets?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<QuoteAssetGetDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-assets/quote/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { includeUnLocatedQuoteAssets: params['includeUnLocatedQuoteAssets'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cloneQuoteAsset(
    params: {
      /** requestBody */
      body?: CloneQuoteAssetsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteAssetGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-assets/clone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QuoteCommentsService {
  /**
   *
   */
  static getAllForQuote(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<QuoteCommentGetDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-comments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-comments/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateQuoteCommentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteCommentDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-comments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateQuoteCommentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-comments';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QuotesService {
  /**
   *
   */
  static setup(
    params: {
      /** requestBody */
      body?: CreateQuoteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      quoteSearchText?: string;
      /**  */
      customerSearchText?: string;
      /**  */
      assigneeUserId?: number;
      /**  */
      showArchived?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<QuoteListingDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        QuoteSearchText: params['quoteSearchText'],
        CustomerSearchText: params['customerSearchText'],
        AssigneeUserId: params['assigneeUserId'],
        ShowArchived: params['showArchived']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateAssignee(
    params: {
      /** requestBody */
      body?: UpdateQuoteAssigneeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/update-assignee';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSetup(
    params: {
      /** requestBody */
      body?: UpdateQuoteSetupRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/update-setup';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitQuoteForReview(
    params: {
      /** requestBody */
      body?: SubmitQuoteForReviewRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/submit-for-review';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static moveQuoteOutOfReview(
    params: {
      /** requestBody */
      body?: MoveQuoteOutOfReviewRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/unreview';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static finalize(
    params: {
      /** requestBody */
      body?: FinalizeQuoteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<Response>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/finalize';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInitialAdditionalValues(
    params: {
      /**  */
      quoteId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteAdditionalValuesDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/additional-values';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { QuoteId: params['quoteId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static saveAdditionalValues(
    params: {
      /** requestBody */
      body?: SaveQuoteAdditionalValuesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<Response>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/additional-values';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendQuoteToLeaseComplete(
    params: {
      /** requestBody */
      body?: SendQuoteToLeaseCompleteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SendQuoteToLeaseCompleteResponseDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/send-to-lease-complete';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cloneQuote(
    params: {
      /** requestBody */
      body?: CloneQuoteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CloneQuoteResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/clone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadLeaseWorksheet(
    params: {
      /**  */
      quoteId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<FileResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/lease-worksheet';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { QuoteId: params['quoteId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadCustomerQuote(
    params: {
      /**  */
      quoteId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<FileResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/customer-quote';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { QuoteId: params['quoteId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDashboardFilterOptions(options: IRequestOptions = {}): Promise<Response<QuoteFilterOptions>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/filter-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSetupOptions(options: IRequestOptions = {}): Promise<Response<QuoteSetupOptions>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/setup-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAssigneeOptions(options: IRequestOptions = {}): Promise<Response<List<OptionItemDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/assignee-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDealerOptions(options: IRequestOptions = {}): Promise<Response<List<OptionItemDto_String>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/dealer-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStateOptions(options: IRequestOptions = {}): Promise<Response<List<OptionItemDto_String>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quotes/state-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QuoteTermsService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateQuoteTermRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteTermGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createFromExistingTerm(
    params: {
      /** requestBody */
      body?: CloneTermsFromExistingTermRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteTermGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms/clone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateQuoteTermRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<TermCalculationResultsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllByAssetId(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<QuoteTermGetDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOptions(
    params: {
      /**  */
      quoteTermId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteTermsSetupOptions>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { quoteTermId: params['quoteTermId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static calculateMonthlyPayment(
    params: {
      /** requestBody */
      body?: CalculateMonthlyPaymentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<TermCalculationResultsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms/calculate-monthly-payment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class QuoteTermsTemplatesService {
  /**
   *
   */
  static createTemplate(
    params: {
      /** requestBody */
      body?: CreateQuoteTermsTemplateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteTermsTemplateGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms-templates';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      customerId?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<QuoteTermsTemplateListingDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms-templates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        customerId: params['customerId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateTemplate(
    params: {
      /** requestBody */
      body?: UpdateQuoteTermsTemplateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteTermsTemplateGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms-templates';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createTemplateFromQuote(
    params: {
      /** requestBody */
      body?: CreateNewQuoteTermsTemplateFromQuoteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteTermsTemplateGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms-templates/create-from-quote';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTemplateOptionsForQuoteAsset(
    params: {
      /**  */
      quoteAssetId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<OptionItemDto_Int32>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms-templates/quote-templates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { QuoteAssetId: params['quoteAssetId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTemplateById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteTermsTemplateGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms-templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteTemplate(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms-templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getQuoteTermsTemplateOptions(options: IRequestOptions = {}): Promise<Response<QuoteTermsTemplateOptionsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms-templates/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createNewQuoteTermsFromTemplate(
    params: {
      /** requestBody */
      body?: CreateNewQuoteTermsFromTemplateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<QuoteTermGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quote-terms-templates/terms-from-template';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RequestTemplateService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<RequestTemplateSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/request-templates';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id?: number;
      /** requestBody */
      body?: UpdateRequestTemplateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RequestTemplateDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/request-templates';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RequestTemplateDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/request-templates/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByKey(
    params: {
      /**  */
      key?: string;
      /**  */
      customerId?: string;
      /**  */
      assetId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RequestTemplateDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/request-templates/key';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Key: params['key'], CustomerId: params['customerId'], AssetId: params['assetId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendRequestEmail(
    params: {
      /**  */
      customerId?: string;
      /**  */
      assetId?: string;
      /**  */
      subject?: string;
      /**  */
      body?: string;
      /**  */
      requestUrl?: string;
      /**  */
      key?: string;
      /**  */
      attachments: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/request-templates/send-request-email';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = {
        CustomerId: params['customerId'],
        AssetId: params['assetId'],
        Subject: params['subject'],
        Body: params['body'],
        RequestUrl: params['requestUrl'],
        Key: params['key']
      };
      let data = null as any;
      data = new FormData();
      if (params['attachments']) {
        if (Object.prototype.toString.call(params['attachments']) === '[object Array]') {
          for (const item of params['attachments']) {
            data.append('Attachments', item as any);
          }
        } else {
          data.append('Attachments', params['attachments'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RoutePatternsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<RoutePatternGetDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/route-patterns';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateRoutePatternRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RoutePatternDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/route-patterns';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RoutePatternDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/route-patterns/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateRoutePatternRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<RoutePatternDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/route-patterns/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/route-patterns/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SalesTaxService {
  /**
   *
   */
  static getSalesTax(
    params: {
      /**  */
      geoCode?: string;
      /**  */
      postalCode?: string;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<SalesTaxListingDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sales-tax/table';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        GeoCode: params['geoCode'],
        PostalCode: params['postalCode'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static calculate(
    params: {
      /**  */
      salesTaxId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<CalculateSalesTaxDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/sales-tax/calculate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { salesTaxId: params['salesTaxId'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<SettingSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SettingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static save(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateSettingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SettingSummaryDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static get(options: IRequestOptions = {}): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAll(
    params: {
      /**  */
      customerId?: string;
      /**  */
      claims?: any | null[];
      /**  */
      roles?: any | null[];
      /**  */
      includeDeleted?: boolean;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: any | null[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<UserSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        CustomerId: params['customerId'],
        Claims: params['claims'],
        Roles: params['roles'],
        IncludeDeleted: params['includeDeleted'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static restoreById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}/restore';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePassword(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateUserPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}/password-update';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendPasswordReset(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}/send-reset';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resendActivationEmail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<boolean>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}/resend-activation-email';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCreateOptions(options: IRequestOptions = {}): Promise<Response<UserOptions>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/create-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUpdateOptions(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<UserOptions>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/update-options/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportUsers(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/export';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setPhoneNumber(
    params: {
      /** requestBody */
      body?: SetPhoneNumberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<SetPhoneNumberResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/set-phone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifyPhoneNumber(
    params: {
      /** requestBody */
      body?: VerifyPhoneNumberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/verify-phone';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCurrentUser(options: IRequestOptions = {}): Promise<Response<UserDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/current';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AddressDto {
  /**  */
  addressId: string;

  /**  */
  addrAddress1: string;

  /**  */
  addrAddress2: string;

  /**  */
  addrAddress3: string;

  /**  */
  addrCity: string;

  /**  */
  addrState: string;

  /**  */
  addrPostalCode: string;

  /**  */
  addrCounty: string;

  /**  */
  addrCountry: string;

  /**  */
  addrPhoneNbr: string;

  /**  */
  addrContact1Name: string;
}

export interface AdvancedQueryColumnFilter {
  /**  */
  fieldName: string;

  /**  */
  fieldValue: string;

  /**  */
  exactMatch: boolean;
}

export interface AdvancedQueryColumnSort {
  /**  */
  fieldName: string;

  /**  */
  descending: boolean;
}

export interface ApprovedQuoteTermDto {
  /**  */
  quoteTermId: number;

  /**  */
  assetName: string;

  /**  */
  capitalizedCost: number;

  /**  */
  term: number;

  /**  */
  monthlyPayment: number;

  /**  */
  dueOnDelivery: number;

  /**  */
  assetId: number;

  /**  */
  companyCost: number;
}

export interface AssetDto {
  /**  */
  assetLeaseId: string;

  /**  */
  isLeaseExpired: boolean;

  /**  */
  isAssetRegistrationExpired: boolean;

  /**  */
  isLeaseExpiring: boolean;

  /**  */
  isAssetRegistrationExpiring: boolean;

  /**  */
  scheduledTerminationDate: Date;

  /**  */
  terminationDate: Date;

  /**  */
  assetLicenseExpDate: Date;

  /**  */
  assetId: string;

  /**  */
  assetCustomerId: string;

  /**  */
  assetStatus: string;

  /**  */
  assetUsageBeginUnits: number;

  /**  */
  assetAddress: AddressDto;

  /**  */
  assetLicenseNbr: string;

  /**  */
  assetLicenseState: string;

  /**  */
  uAssetsDriverName1: string;

  /**  */
  assetSerialNbr: string;

  /**  */
  assetDescription: string;

  /**  */
  uAssetsCustInternalCode: string;

  /**  */
  assetLease: LeaseDto;

  /**  */
  uAssetsInspectionReqMileage: boolean;

  /**  */
  uAssetsInspectionReqEmissionsInspection: boolean;

  /**  */
  uAssetsInspectionReqSmogInspection: boolean;

  /**  */
  uAssetsInspectionReqSafetyInspection: boolean;

  /**  */
  uAssetsInspectionReqVinverification: boolean;

  /**  */
  uAssetsCertifiedWeightTicket: boolean;

  /**  */
  uAssetsComments: string;

  /**  */
  assetType: string;

  /**  */
  registrationStatus: string;

  /**  */
  isUnderMaintenancePlan: boolean;

  /**  */
  hasExecutedContract: boolean;

  /**  */
  monthsRemainingInLease: number;

  /**  */
  monthsInService: number;

  /**  */
  uAssetsSentForProcessing: Date;
}

export interface AssetListingsDto {
  /**  */
  assetLeaseId: string;

  /**  */
  isLeaseExpired: boolean;

  /**  */
  isAssetRegistrationExpired: boolean;

  /**  */
  isLeaseExpiring: boolean;

  /**  */
  isAssetRegistrationExpiring: boolean;

  /**  */
  scheduledTerminationDate: Date;

  /**  */
  terminationDate: Date;

  /**  */
  assetLicenseExpDate: Date;

  /**  */
  id: string;

  /**  */
  status: string;

  /**  */
  type: string;

  /**  */
  description: string;

  /**  */
  serialNumber: string;

  /**  */
  contractDate: Date;

  /**  */
  plateNumber: string;

  /**  */
  assetStateAbbreviation: string;

  /**  */
  assetUnitNumber: string;

  /**  */
  isUnderMaintenancePlan: boolean;

  /**  */
  driverName: string;

  /**  */
  branchDivision: string;

  /**  */
  assetGlCode: string;

  /**  */
  assetStatus: string;
}

export interface AssetMaintenanceVisit {
  /**  */
  visitDate: Date;

  /**  */
  totalCost: number;

  /**  */
  provider: string;

  /**  */
  invoiveNbr: string;

  /**  */
  mileage: number;

  /**  */
  showTotal: boolean;
}

export interface AssetUsageDetailDto {
  /**  */
  assetId: string;

  /**  */
  usageUnits: number;

  /**  */
  readingDate: Date;

  /**  */
  notes: string;

  /**  */
  id: number;

  /**  */
  createdByUserName: string;
}

export interface BillingHistoryDto {
  /**  */
  effectiveDate: Date;

  /**  */
  paymentMethod: string;

  /**  */
  amount: number;

  /**  */
  description: string;

  /**  */
  dueDate: Date;
}

export interface BillingHistoryListingDto {
  /**  */
  bhId: string;

  /**  */
  dueDate: Date;

  /**  */
  satisfiedDate: Date;

  /**  */
  billType: string;

  /**  */
  amount: number;

  /**  */
  description: string;

  /**  */
  assetId: string;

  /**  */
  glCode: string;

  /**  */
  bhLevelCode: string;
}

export interface BillingSummaryDto {
  /**  */
  totalAmount: number;

  /**  */
  totalCharges: number;
}

export interface CalculateMonthlyPaymentRequest {
  /**  */
  markupProfit: number;

  /**  */
  salesTax: number;

  /**  */
  salesTaxType: string;

  /**  */
  salesTaxOverride: boolean;

  /**  */
  salesTaxOverrideAmount: number;

  /**  */
  registration: number;

  /**  */
  registrationType: string;

  /**  */
  capAmountUpfit: number;

  /**  */
  capAmountTransportAmount: number;

  /**  */
  capAmountLeaseOrigFee: number;

  /**  */
  capAmountDocFee: number;

  /**  */
  capAmountProrate: number;

  /**  */
  capAmountPostageFee: number;

  /**  */
  capAmountEquity: number;

  /**  */
  capAmountAdditionalFeeDescription1: string;

  /**  */
  capAmountAdditionalFeeAmount1: number;

  /**  */
  capAmountAdditionalFeeDescription2: string;

  /**  */
  capAmountAdditionalFeeAmount2: number;

  /**  */
  capCostReduction: number;

  /**  */
  capCostReductionType: string;

  /**  */
  dueOnDeliverySecurityDeposit: number;

  /**  */
  dueOnDeliveryOtherDeposit: number;

  /**  */
  dueOnDeliveryUpfit: number;

  /**  */
  dueOnDeliveryTransportAmount: number;

  /**  */
  dueOnDeliveryLeaseOrigFee: number;

  /**  */
  dueOnDeliveryTms: number;

  /**  */
  dueOnDeliveryInsurance: number;

  /**  */
  dueOnDeliveryGps: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription1: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount1: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription2: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount2: number;

  /**  */
  lessTradeInEquity: number;

  /**  */
  term: number;

  /**  */
  apr: number;

  /**  */
  cashFlow: number;

  /**  */
  contractBeginDate: Date;

  /**  */
  msrp: number;

  /**  */
  invoice: number;

  /**  */
  stateTaxId: string;

  /**  */
  countyTaxId: string;

  /**  */
  cityTaxId: string;

  /**  */
  paymentCalcCode: string;

  /**  */
  calculateProrate: boolean;

  /**  */
  id: number;

  /**  */
  quoteAssetId: number;

  /**  */
  capitalizedCost: number;

  /**  */
  residualValue: number;

  /**  */
  monthlyPayment: number;

  /**  */
  dueOnDelivery: number;

  /**  */
  showOnCustomerWorksheet: boolean;

  /**  */
  quoteTermId: number;
}

export interface CalculateSalesTaxDto {
  /**  */
  salesTaxRate: number;

  /**  */
  stateTaxId: string;

  /**  */
  countyTaxId: string;

  /**  */
  cityTaxId: string;
}

export interface ClientPaymentSessionResponse {
  /**  */
  paymentLogInLink: string;

  /**  */
  paymentSessionIdentifier: string;
}

export interface CloneQuoteAssetsRequest {
  /**  */
  quoteAssetId: number;
}

export interface CloneQuoteRequest {
  /**  */
  quoteId: number;
}

export interface CloneQuoteResponse {
  /**  */
  newQuoteId: number;
}

export interface CloneTermsFromExistingTermRequest {
  /**  */
  quoteAssetId: number;

  /**  */
  quoteTermId: number;
}

export interface CreateAssetUsageRequest {
  /**  */
  assetId: string;

  /**  */
  usageUnits: number;

  /**  */
  readingDate: Date;

  /**  */
  notes: string;
}

export interface CreateNewQuoteTermsFromTemplateRequest {
  /**  */
  quoteTermId: number;

  /**  */
  quoteTermsTemplateId: number;
}

export interface CreateNewQuoteTermsTemplateFromQuoteRequest {
  /**  */
  quoteId: number;

  /**  */
  name: string;
}

export interface CreateQuoteAssetRequest {
  /**  */
  assetType: string;

  /**  */
  useType: string;

  /**  */
  year: number;

  /**  */
  make: string;

  /**  */
  model: string;

  /**  */
  trimLevel: string;

  /**  */
  upfit: string;

  /**  */
  requestedTerm: string;

  /**  */
  currentMileage: number;

  /**  */
  estimatedAnnualMileage: number;

  /**  */
  garagingAddress: string;

  /**  */
  garagingRegion: string;

  /**  */
  vin: string;

  /**  */
  dealerId: string;

  /**  */
  residual: number;

  /**  */
  companyCost: number;

  /**  */
  residualType: string;

  /**  */
  assetStatus: string;

  /**  */
  excessMileageCharge: number;

  /**  */
  isForklift: boolean;

  /**  */
  description: string;

  /**  */
  quoteId: number;
}

export interface CreateQuoteCommentRequest {
  /**  */
  quoteId: number;

  /**  */
  text: string;
}

export interface CreateQuoteRequest {
  /**  */
  customerId: string;

  /**  */
  customerName: string;

  /**  */
  description: string;

  /**  */
  contractType: string;

  /**  */
  isFloatRate: boolean;
}

export interface CreateQuoteTermRequest {
  /**  */
  markupProfit: number;

  /**  */
  salesTax: number;

  /**  */
  salesTaxType: string;

  /**  */
  salesTaxOverride: boolean;

  /**  */
  salesTaxOverrideAmount: number;

  /**  */
  registration: number;

  /**  */
  registrationType: string;

  /**  */
  capAmountUpfit: number;

  /**  */
  capAmountTransportAmount: number;

  /**  */
  capAmountLeaseOrigFee: number;

  /**  */
  capAmountDocFee: number;

  /**  */
  capAmountProrate: number;

  /**  */
  capAmountPostageFee: number;

  /**  */
  capAmountEquity: number;

  /**  */
  capAmountAdditionalFeeDescription1: string;

  /**  */
  capAmountAdditionalFeeAmount1: number;

  /**  */
  capAmountAdditionalFeeDescription2: string;

  /**  */
  capAmountAdditionalFeeAmount2: number;

  /**  */
  capCostReduction: number;

  /**  */
  capCostReductionType: string;

  /**  */
  dueOnDeliverySecurityDeposit: number;

  /**  */
  dueOnDeliveryOtherDeposit: number;

  /**  */
  dueOnDeliveryUpfit: number;

  /**  */
  dueOnDeliveryTransportAmount: number;

  /**  */
  dueOnDeliveryLeaseOrigFee: number;

  /**  */
  dueOnDeliveryTms: number;

  /**  */
  dueOnDeliveryInsurance: number;

  /**  */
  dueOnDeliveryGps: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription1: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount1: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription2: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount2: number;

  /**  */
  lessTradeInEquity: number;

  /**  */
  term: number;

  /**  */
  apr: number;

  /**  */
  cashFlow: number;

  /**  */
  contractBeginDate: Date;

  /**  */
  msrp: number;

  /**  */
  invoice: number;

  /**  */
  stateTaxId: string;

  /**  */
  countyTaxId: string;

  /**  */
  cityTaxId: string;

  /**  */
  paymentCalcCode: string;

  /**  */
  calculateProrate: boolean;

  /**  */
  id: number;

  /**  */
  quoteAssetId: number;

  /**  */
  capitalizedCost: number;

  /**  */
  residualValue: number;

  /**  */
  monthlyPayment: number;

  /**  */
  dueOnDelivery: number;

  /**  */
  showOnCustomerWorksheet: boolean;
}

export interface CreateQuoteTermsTemplateRequest {
  /**  */
  markupProfit: number;

  /**  */
  salesTax: number;

  /**  */
  salesTaxType: string;

  /**  */
  salesTaxOverride: boolean;

  /**  */
  salesTaxOverrideAmount: number;

  /**  */
  registration: number;

  /**  */
  registrationType: string;

  /**  */
  capAmountUpfit: number;

  /**  */
  capAmountTransportAmount: number;

  /**  */
  capAmountLeaseOrigFee: number;

  /**  */
  capAmountDocFee: number;

  /**  */
  capAmountProrate: number;

  /**  */
  capAmountPostageFee: number;

  /**  */
  capAmountEquity: number;

  /**  */
  capAmountAdditionalFeeDescription1: string;

  /**  */
  capAmountAdditionalFeeAmount1: number;

  /**  */
  capAmountAdditionalFeeDescription2: string;

  /**  */
  capAmountAdditionalFeeAmount2: number;

  /**  */
  capCostReduction: number;

  /**  */
  capCostReductionType: string;

  /**  */
  dueOnDeliverySecurityDeposit: number;

  /**  */
  dueOnDeliveryOtherDeposit: number;

  /**  */
  dueOnDeliveryUpfit: number;

  /**  */
  dueOnDeliveryTransportAmount: number;

  /**  */
  dueOnDeliveryLeaseOrigFee: number;

  /**  */
  dueOnDeliveryTms: number;

  /**  */
  dueOnDeliveryInsurance: number;

  /**  */
  dueOnDeliveryGps: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription1: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount1: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription2: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount2: number;

  /**  */
  lessTradeInEquity: number;

  /**  */
  term: number;

  /**  */
  apr: number;

  /**  */
  cashFlow: number;

  /**  */
  contractBeginDate: Date;

  /**  */
  msrp: number;

  /**  */
  invoice: number;

  /**  */
  stateTaxId: string;

  /**  */
  countyTaxId: string;

  /**  */
  cityTaxId: string;

  /**  */
  paymentCalcCode: string;

  /**  */
  calculateProrate: boolean;

  /**  */
  customerId: string;

  /**  */
  name: string;
}

export interface CreateRoutePatternRequest {
  /**  */
  description: string;

  /**  */
  pattern: string;
}

export interface CreateUserRequest {
  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  role: string;

  /**  */
  customers: string[];

  /**  */
  claims: string[];
}

export interface CustDashboardDto {
  /**  */
  id: string;

  /**  */
  name: string;

  /**  */
  officerName: string;

  /**  */
  officerEmail: string;

  /**  */
  officerPhone: string;

  /**  */
  relationOfficerName: string;

  /**  */
  relationOfficerEmail: string;

  /**  */
  relationOfficerPhone: string;
}

export interface CustDetailDto {
  /**  */
  id: string;

  /**  */
  name: string;

  /**  */
  type: string;

  /**  */
  officerName: string;

  /**  */
  relationOfficerName: string;
}

export interface CustListingDto {
  /**  */
  id: string;

  /**  */
  nameFirst: string;

  /**  */
  nameBusiness: string;

  /**  */
  nameSuffix: string;

  /**  */
  type: string;

  /**  */
  officerName: string;

  /**  */
  relationOfficerName: string;
}

export interface CustSummaryDto {
  /**  */
  id: string;

  /**  */
  name: string;
}

export interface CustomerApproveQuoteTermRequest {
  /**  */
  quoteTermId: number;
}

export interface CustomerDenyQuoteRequest {
  /**  */
  quoteId: number;
}

export interface CustomerSettingsDto {
  /**  */
  id: number;

  /**  */
  customerId: string;

  /**  */
  displayUnitNumber: boolean;

  /**  */
  displayDriver: boolean;

  /**  */
  displayBranchDivision: boolean;

  /**  */
  geotabId: string;
}

export interface DashboardStatisticsDto {
  /**  */
  reviewDate: Date;

  /**  */
  expiredRegistrations: number;

  /**  */
  upcomingRegistrationExpirations: number;

  /**  */
  upcomingPastDueLeases: number;

  /**  */
  pastDueLeases: number;

  /**  */
  assetsUnderMaintenancePlans: number;

  /**  */
  assetsNotUnderMaintenancePlans: number;
}

export interface EmptyResponse {
  /**  */
  hasErrors: boolean;

  /**  */
  errors: Error[];
}

export interface Error {
  /**  */
  propertyName: string;

  /**  */
  errorMessage: string;
}

export interface ExampleType {
  /**  */
  someProperty: string;
}

export interface ExcelExportSettingDto {
  /**  */
  heading: string;

  /**  */
  columnId: number;

  /**  */
  selected: boolean;
}

export interface ExportablePdfDto {
  /**  */
  name: string;

  /**  */
  filename: string;

  /**  */
  key: string;

  /**  */
  exportFields: ExportablePdfFieldDto[];
}

export interface ExportablePdfFieldDto {
  /**  */
  pdfFieldName: string;

  /**  */
  mappedEntityField: string;
}

export interface FileResponse {
  /**  */
  fileName: string;

  /**  */
  stream: MemoryStream;
}

export interface FinalizeQuoteRequest {
  /**  */
  quoteId: number;
}

export interface FinancialInformationDto {
  /**  */
  currentBalance: number;

  /**  */
  latestInvoice: LatestInvoiceDto;
}

export interface FleetCount {
  /**  */
  status: string;

  /**  */
  count: number;
}

export interface FleetExpense {
  /**  */
  type: string;

  /**  */
  amount: number;
}

export interface FleetExpensesDto {
  /**  */
  expenses: FleetExpense[];
}

export interface FleetOverviewDto {
  /**  */
  fleetCounts: FleetCount[];
}

export interface GetCurrentInvoiceGenerationBatchResponse {
  /**  */
  isGenerating: boolean;

  /**  */
  numComplete: number;

  /**  */
  total: number;
}

export interface GetManagerFleetOverviewRequest {}

export interface InitiatePaystarPaymentSessionRequest {
  /**  */
  amount: number;

  /**  */
  customerId: string;
}

export interface InsPolicyDto {
  /**  */
  inspolId: number;

  /**  */
  inspolPolicyNumber: string;

  /**  */
  inspolInactiveInd: boolean;

  /**  */
  inspolBeginDate: Date;
}

export interface InvoiceGetDto {
  /**  */
  customerId: string;

  /**  */
  invoiceNumber: string;

  /**  */
  amount: number;

  /**  */
  invoiceDate: Date;

  /**  */
  dueDate: Date;

  /**  */
  filePath: string;

  /**  */
  isSupplemental: boolean;

  /**  */
  id: number;
}

export interface InvoiceListingDto {
  /**  */
  id: number;

  /**  */
  invoiceNumber: string;

  /**  */
  amount: number;

  /**  */
  invoiceDate: Date;

  /**  */
  dueDate: Date;

  /**  */
  isSupplemental: boolean;
}

export interface LatestInvoiceDto {
  /**  */
  id: number;

  /**  */
  amount: number;

  /**  */
  dueDate: Date;

  /**  */
  invoiceNumber: string;
}

export interface LeaseDto {
  /**  */
  leaseId: string;

  /**  */
  leaseFirstPmtDate: Date;

  /**  */
  leaseLastPmtDate: Date;

  /**  */
  leaseBasePmtAmt: number;

  /**  */
  leaseStatus: string;

  /**  */
  leaseResidualAmt: number;

  /**  */
  leaseInsurancePolicies: InsPolicyDto[];

  /**  */
  currentPolicy: InsPolicyDto;
}

export interface LogGroupNotesDto {
  /**  */
  notes: string;

  /**  */
  matchedPattern: string;

  /**  */
  status: string;

  /**  */
  errorMessages: string;
}

export interface LogGroupNotesGetDto {
  /**  */
  notes: string;

  /**  */
  matchedPattern: string;

  /**  */
  status: string;

  /**  */
  errorMessages: string;

  /**  */
  id: number;
}

export interface LogSummaryDetailDto {
  /**  */
  id: number;

  /**  */
  timestamp: Date;

  /**  */
  trace: string;

  /**  */
  userId: string;

  /**  */
  ipAddress: string;

  /**  */
  route: string;

  /**  */
  duration: string;

  /**  */
  stack: string;

  /**  */
  handledByUserId: number;

  /**  */
  handledStatus: string;

  /**  */
  notes: string;

  /**  */
  markedAsHandledTimestamp: Date;
}

export interface LogSummaryGetDto {
  /**  */
  timestamp: Date;

  /**  */
  trace: string;

  /**  */
  userId: string;

  /**  */
  ipAddress: string;

  /**  */
  route: string;

  /**  */
  matchedPatterns: string;

  /**  */
  status: string;

  /**  */
  duration: string;

  /**  */
  errorMessages: string;

  /**  */
  stack: string;

  /**  */
  handledByUserId: number;

  /**  */
  handledStatus: string;

  /**  */
  notes: string;

  /**  */
  important: boolean;

  /**  */
  markedAsHandledTimestamp: Date;

  /**  */
  logFileId: number;

  /**  */
  id: number;
}

export interface LogSummaryGroupListItem {
  /**  */
  id: number;

  /**  */
  createdByUserId: number;

  /**  */
  modifiedByUserId: number;

  /**  */
  deletedByUserId: number;

  /**  */
  isDeleted: boolean;

  /**  */
  createdDate: Date;

  /**  */
  modifiedDate: Date;

  /**  */
  deletedDate: Date;

  /**  */
  status: string;

  /**  */
  matchedPattern: string;

  /**  */
  occurrences: number;

  /**  */
  errorMessages: string;

  /**  */
  important: boolean;

  /**  */
  hasUnhandledOccurrences: boolean;

  /**  */
  firstOccurrenceTimestamp: Date;

  /**  */
  lastOccurrenceTimestamp: Date;
}

export interface LogSummaryListDto {
  /**  */
  id: number;

  /**  */
  matchedPatterns: string;

  /**  */
  route: string;

  /**  */
  timestamp: Date;

  /**  */
  duration: string;

  /**  */
  handledStatus: string;
}

export interface MemoryStream {
  /**  */
  canTimeout: boolean;

  /**  */
  readTimeout: number;

  /**  */
  writeTimeout: number;

  /**  */
  canRead: boolean;

  /**  */
  canSeek: boolean;

  /**  */
  canWrite: boolean;

  /**  */
  capacity: number;

  /**  */
  length: number;

  /**  */
  position: number;
}

export interface MonthlyInvoiceDto {
  /**  */
  customerId: string;

  /**  */
  nameFirst: string;

  /**  */
  nameBusiness: string;

  /**  */
  nameSuffix: string;

  /**  */
  invoiceId: number;

  /**  */
  invoiceNumber: string;

  /**  */
  amount: number;

  /**  */
  invoiceDate: Date;

  /**  */
  dueDate: Date;

  /**  */
  shouldGenerateInvoice: boolean;
}

export interface MoveQuoteOutOfReviewRequest {
  /**  */
  quoteId: number;
}

export interface OptionItemDto {
  /**  */
  value: number;

  /**  */
  text: string;

  /**  */
  meta: any | null;
}

export interface OptionItemDto_Int32 {
  /**  */
  value: number;

  /**  */
  text: string;

  /**  */
  meta: any | null;
}

export interface OptionItemDto_String {
  /**  */
  value: string;

  /**  */
  text: string;

  /**  */
  meta: any | null;
}

export interface PmServiceDto {
  /**  */
  description: string;

  /**  */
  cost: number;

  /**  */
  planType: string;
}

export interface QuoteAdditionalValuesDto {
  /**  */
  customerFirstName: string;

  /**  */
  customerLastNameBusinessName: string;

  /**  */
  customerSuffix: string;

  /**  */
  customerContactName: string;

  /**  */
  customerAddress1: string;

  /**  */
  customerAddress2: string;

  /**  */
  customerCity: string;

  /**  */
  customerStateId: string;

  /**  */
  customerPostalCode: string;

  /**  */
  customerPhoneNumber: string;

  /**  */
  customerAltPhoneNumber: string;

  /**  */
  vin: string;

  /**  */
  newAssetId: string;
}

export interface QuoteAssetGetDto {
  /**  */
  assetType: string;

  /**  */
  useType: string;

  /**  */
  year: number;

  /**  */
  make: string;

  /**  */
  model: string;

  /**  */
  trimLevel: string;

  /**  */
  upfit: string;

  /**  */
  requestedTerm: string;

  /**  */
  currentMileage: number;

  /**  */
  estimatedAnnualMileage: number;

  /**  */
  garagingAddress: string;

  /**  */
  garagingRegion: string;

  /**  */
  vin: string;

  /**  */
  dealerId: string;

  /**  */
  residual: number;

  /**  */
  companyCost: number;

  /**  */
  residualType: string;

  /**  */
  assetStatus: string;

  /**  */
  excessMileageCharge: number;

  /**  */
  isForklift: boolean;

  /**  */
  description: string;

  /**  */
  id: number;

  /**  */
  quoteId: number;

  /**  */
  termsConfigured: number;
}

export interface QuoteCommentDto {
  /**  */
  id: number;

  /**  */
  quoteId: number;

  /**  */
  text: string;
}

export interface QuoteCommentGetDto {
  /**  */
  id: number;

  /**  */
  text: string;

  /**  */
  createdByUserId: number;

  /**  */
  createdByUserName: string;

  /**  */
  createdDate: Date;

  /**  */
  canModify: boolean;
}

export interface QuoteFilterOptions {
  /**  */
  customers: OptionItemDto_String[];

  /**  */
  assignedUsers: OptionItemDto_Int32[];

  /**  */
  assetTypes: OptionItemDto_String[];
}

export interface QuoteGetDto {
  /**  */
  assignedUserId: number;

  /**  */
  quoteStage: string;

  /**  */
  quoteStatus: string;

  /**  */
  isArchived: boolean;

  /**  */
  customerId: string;

  /**  */
  customerName: string;

  /**  */
  description: string;

  /**  */
  approvedQuoteTermId: number;

  /**  */
  contractType: string;

  /**  */
  quoteCustomerId: number;

  /**  */
  leaseCompleteQuoteId: number;

  /**  */
  isFloatRate: boolean;

  /**  */
  newAssetId: string;

  /**  */
  id: number;

  /**  */
  assignedUserName: string;

  /**  */
  facilityStatus: string;

  /**  */
  createdDate: Date;

  /**  */
  modifiedDate: Date;

  /**  */
  dealerName: string;

  /**  */
  totalCredit: number;

  /**  */
  availableCredit: number;

  /**  */
  quoteAssets: QuoteAssetGetDto[];

  /**  */
  approvedQuoteTerm: ApprovedQuoteTermDto;
}

export interface QuoteListingDto {
  /**  */
  id: number;

  /**  */
  quoteStage: string;

  /**  */
  customerId: string;

  /**  */
  customerName: string;

  /**  */
  facilityStatus: string;

  /**  */
  quoteStatus: string;

  /**  */
  assignedUserId: number;

  /**  */
  assignedUserName: string;

  /**  */
  createdDate: Date;

  /**  */
  modifiedDate: Date;

  /**  */
  isArchived: boolean;
}

export interface QuoteSetupOptions {
  /**  */
  customers: OptionItemDto_String[];
}

export interface QuoteTermGetDto {
  /**  */
  markupProfit: number;

  /**  */
  salesTax: number;

  /**  */
  salesTaxType: string;

  /**  */
  salesTaxOverride: boolean;

  /**  */
  salesTaxOverrideAmount: number;

  /**  */
  registration: number;

  /**  */
  registrationType: string;

  /**  */
  capAmountUpfit: number;

  /**  */
  capAmountTransportAmount: number;

  /**  */
  capAmountLeaseOrigFee: number;

  /**  */
  capAmountDocFee: number;

  /**  */
  capAmountProrate: number;

  /**  */
  capAmountPostageFee: number;

  /**  */
  capAmountEquity: number;

  /**  */
  capAmountAdditionalFeeDescription1: string;

  /**  */
  capAmountAdditionalFeeAmount1: number;

  /**  */
  capAmountAdditionalFeeDescription2: string;

  /**  */
  capAmountAdditionalFeeAmount2: number;

  /**  */
  capCostReduction: number;

  /**  */
  capCostReductionType: string;

  /**  */
  dueOnDeliverySecurityDeposit: number;

  /**  */
  dueOnDeliveryOtherDeposit: number;

  /**  */
  dueOnDeliveryUpfit: number;

  /**  */
  dueOnDeliveryTransportAmount: number;

  /**  */
  dueOnDeliveryLeaseOrigFee: number;

  /**  */
  dueOnDeliveryTms: number;

  /**  */
  dueOnDeliveryInsurance: number;

  /**  */
  dueOnDeliveryGps: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription1: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount1: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription2: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount2: number;

  /**  */
  lessTradeInEquity: number;

  /**  */
  term: number;

  /**  */
  apr: number;

  /**  */
  cashFlow: number;

  /**  */
  contractBeginDate: Date;

  /**  */
  msrp: number;

  /**  */
  invoice: number;

  /**  */
  stateTaxId: string;

  /**  */
  countyTaxId: string;

  /**  */
  cityTaxId: string;

  /**  */
  paymentCalcCode: string;

  /**  */
  calculateProrate: boolean;

  /**  */
  id: number;

  /**  */
  quoteAssetId: number;

  /**  */
  capitalizedCost: number;

  /**  */
  residualValue: number;

  /**  */
  monthlyPayment: number;

  /**  */
  dueOnDelivery: number;

  /**  */
  showOnCustomerWorksheet: boolean;
}

export interface QuoteTermsSetupOptions {
  /**  */
  quoteAssets: OptionItemDto_Int32[];
}

export interface QuoteTermsTemplateGetDto {
  /**  */
  markupProfit: number;

  /**  */
  salesTax: number;

  /**  */
  salesTaxType: string;

  /**  */
  salesTaxOverride: boolean;

  /**  */
  salesTaxOverrideAmount: number;

  /**  */
  registration: number;

  /**  */
  registrationType: string;

  /**  */
  capAmountUpfit: number;

  /**  */
  capAmountTransportAmount: number;

  /**  */
  capAmountLeaseOrigFee: number;

  /**  */
  capAmountDocFee: number;

  /**  */
  capAmountProrate: number;

  /**  */
  capAmountPostageFee: number;

  /**  */
  capAmountEquity: number;

  /**  */
  capAmountAdditionalFeeDescription1: string;

  /**  */
  capAmountAdditionalFeeAmount1: number;

  /**  */
  capAmountAdditionalFeeDescription2: string;

  /**  */
  capAmountAdditionalFeeAmount2: number;

  /**  */
  capCostReduction: number;

  /**  */
  capCostReductionType: string;

  /**  */
  dueOnDeliverySecurityDeposit: number;

  /**  */
  dueOnDeliveryOtherDeposit: number;

  /**  */
  dueOnDeliveryUpfit: number;

  /**  */
  dueOnDeliveryTransportAmount: number;

  /**  */
  dueOnDeliveryLeaseOrigFee: number;

  /**  */
  dueOnDeliveryTms: number;

  /**  */
  dueOnDeliveryInsurance: number;

  /**  */
  dueOnDeliveryGps: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription1: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount1: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription2: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount2: number;

  /**  */
  lessTradeInEquity: number;

  /**  */
  term: number;

  /**  */
  apr: number;

  /**  */
  cashFlow: number;

  /**  */
  contractBeginDate: Date;

  /**  */
  msrp: number;

  /**  */
  invoice: number;

  /**  */
  stateTaxId: string;

  /**  */
  countyTaxId: string;

  /**  */
  cityTaxId: string;

  /**  */
  paymentCalcCode: string;

  /**  */
  calculateProrate: boolean;

  /**  */
  customerId: string;

  /**  */
  name: string;

  /**  */
  id: number;
}

export interface QuoteTermsTemplateListingDto {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  lastUpdatedBy: string;

  /**  */
  lastUpdatedOn: Date;

  /**  */
  customerName: string;

  /**  */
  customerId: string;
}

export interface QuoteTermsTemplateOptionsDto {
  /**  */
  customers: OptionItemDto_String[];
}

export interface RequestTemplateDetailDto {
  /**  */
  name: string;

  /**  */
  subject: string;

  /**  */
  body: string;

  /**  */
  notifyAccountExecutive: boolean;

  /**  */
  notifyCustomerServiceRepresentative: boolean;

  /**  */
  additionalRecipients: number[];

  /**  */
  key: string;

  /**  */
  id: number;

  /**  */
  intendedRecipients: string;
}

export interface RequestTemplateSummaryDto {
  /**  */
  id: number;

  /**  */
  name: string;

  /**  */
  modifiedDate: Date;

  /**  */
  modifiedByUserName: string;
}

export interface RoutePatternDto {
  /**  */
  description: string;

  /**  */
  pattern: string;
}

export interface RoutePatternGetDto {
  /**  */
  description: string;

  /**  */
  pattern: string;

  /**  */
  id: number;
}

export interface SalesTaxListingDto {
  /**  */
  salestaxId: string;

  /**  */
  salestaxType: string;

  /**  */
  salestaxName: string;

  /**  */
  salestaxStateName: string;

  /**  */
  salestaxCountyName: string;

  /**  */
  salestaxGeoCode: string;

  /**  */
  salestaxPostalCodeBegin: string;

  /**  */
  salestaxPostalCodeEnd: string;
}

export interface SaveCustomerExportColumnsRequest {
  /**  */
  customerId: string;

  /**  */
  columns: number[];
}

export interface SaveQuoteAdditionalValuesRequest {
  /**  */
  customerFirstName: string;

  /**  */
  customerLastNameBusinessName: string;

  /**  */
  customerSuffix: string;

  /**  */
  customerContactName: string;

  /**  */
  customerAddress1: string;

  /**  */
  customerAddress2: string;

  /**  */
  customerCity: string;

  /**  */
  customerStateId: string;

  /**  */
  customerPostalCode: string;

  /**  */
  customerPhoneNumber: string;

  /**  */
  customerAltPhoneNumber: string;

  /**  */
  vin: string;

  /**  */
  newAssetId: string;

  /**  */
  quoteId: number;
}

export interface SendQuoteToLeaseCompleteRequest {
  /**  */
  quoteId: number;
}

export interface SendQuoteToLeaseCompleteResponseDto {
  /**  */
  newLeaseCompleteQuoteId: number;
}

export interface SetPhoneNumberRequest {
  /**  */
  phoneNumber: string;
}

export interface SetPhoneNumberResponse {
  /**  */
  phoneNumber: string;
}

export interface SettingSummaryDto {
  /**  */
  value: string;

  /**  */
  id: number;

  /**  */
  key: string;

  /**  */
  type: string;

  /**  */
  enum: string;
}

export interface SubmitQuoteForReviewRequest {
  /**  */
  quoteId: number;
}

export interface SupplementalInvoiceDto {
  /**  */
  id: number;

  /**  */
  customerId: string;

  /**  */
  customerName: string;

  /**  */
  invoiceNumber: string;

  /**  */
  amount: number;

  /**  */
  invoiceDate: Date;

  /**  */
  dueDate: Date;
}

export interface TermCalculationResultsDto {
  /**  */
  capitalizedCost: number;

  /**  */
  residualValue: number;

  /**  */
  monthlyPayment: number;

  /**  */
  dueOnDelivery: number;
}

export interface UpdateAssetRequest {
  /**  */
  assetId: string;

  /**  */
  division: string;

  /**  */
  driverName: string;

  /**  */
  unit: string;
}

export interface UpdateCustomerSettingsRequest {
  /**  */
  customerId: string;

  /**  */
  displayUnitNumber: boolean;

  /**  */
  displayDriver: boolean;

  /**  */
  displayBranchDivision: boolean;

  /**  */
  geotabId: string;
}

export interface UpdateQuoteAssetRequest {
  /**  */
  assetType: string;

  /**  */
  useType: string;

  /**  */
  year: number;

  /**  */
  make: string;

  /**  */
  model: string;

  /**  */
  trimLevel: string;

  /**  */
  upfit: string;

  /**  */
  requestedTerm: string;

  /**  */
  currentMileage: number;

  /**  */
  estimatedAnnualMileage: number;

  /**  */
  garagingAddress: string;

  /**  */
  garagingRegion: string;

  /**  */
  vin: string;

  /**  */
  dealerId: string;

  /**  */
  residual: number;

  /**  */
  companyCost: number;

  /**  */
  residualType: string;

  /**  */
  assetStatus: string;

  /**  */
  excessMileageCharge: number;

  /**  */
  isForklift: boolean;

  /**  */
  description: string;

  /**  */
  id: number;

  /**  */
  quoteId: number;

  /**  */
  termsConfigured: number;
}

export interface UpdateQuoteAssigneeRequest {
  /**  */
  quoteId: number;

  /**  */
  assignedUserId: number;
}

export interface UpdateQuoteCommentRequest {
  /**  */
  id: number;

  /**  */
  text: string;
}

export interface UpdateQuoteManagerApprovalStatusRequest {
  /**  */
  quoteId: number;

  /**  */
  managerApprovalStatus: string;
}

export interface UpdateQuoteSetupRequest {
  /**  */
  customerId: string;

  /**  */
  customerName: string;

  /**  */
  description: string;

  /**  */
  contractType: string;

  /**  */
  isFloatRate: boolean;

  /**  */
  id: number;
}

export interface UpdateQuoteTermRequest {
  /**  */
  markupProfit: number;

  /**  */
  salesTax: number;

  /**  */
  salesTaxType: string;

  /**  */
  salesTaxOverride: boolean;

  /**  */
  salesTaxOverrideAmount: number;

  /**  */
  registration: number;

  /**  */
  registrationType: string;

  /**  */
  capAmountUpfit: number;

  /**  */
  capAmountTransportAmount: number;

  /**  */
  capAmountLeaseOrigFee: number;

  /**  */
  capAmountDocFee: number;

  /**  */
  capAmountProrate: number;

  /**  */
  capAmountPostageFee: number;

  /**  */
  capAmountEquity: number;

  /**  */
  capAmountAdditionalFeeDescription1: string;

  /**  */
  capAmountAdditionalFeeAmount1: number;

  /**  */
  capAmountAdditionalFeeDescription2: string;

  /**  */
  capAmountAdditionalFeeAmount2: number;

  /**  */
  capCostReduction: number;

  /**  */
  capCostReductionType: string;

  /**  */
  dueOnDeliverySecurityDeposit: number;

  /**  */
  dueOnDeliveryOtherDeposit: number;

  /**  */
  dueOnDeliveryUpfit: number;

  /**  */
  dueOnDeliveryTransportAmount: number;

  /**  */
  dueOnDeliveryLeaseOrigFee: number;

  /**  */
  dueOnDeliveryTms: number;

  /**  */
  dueOnDeliveryInsurance: number;

  /**  */
  dueOnDeliveryGps: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription1: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount1: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription2: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount2: number;

  /**  */
  lessTradeInEquity: number;

  /**  */
  term: number;

  /**  */
  apr: number;

  /**  */
  cashFlow: number;

  /**  */
  contractBeginDate: Date;

  /**  */
  msrp: number;

  /**  */
  invoice: number;

  /**  */
  stateTaxId: string;

  /**  */
  countyTaxId: string;

  /**  */
  cityTaxId: string;

  /**  */
  paymentCalcCode: string;

  /**  */
  calculateProrate: boolean;

  /**  */
  quoteAssetId: number;

  /**  */
  capitalizedCost: number;

  /**  */
  residualValue: number;

  /**  */
  monthlyPayment: number;

  /**  */
  dueOnDelivery: number;

  /**  */
  showOnCustomerWorksheet: boolean;
}

export interface UpdateQuoteTermsTemplateRequest {
  /**  */
  markupProfit: number;

  /**  */
  salesTax: number;

  /**  */
  salesTaxType: string;

  /**  */
  salesTaxOverride: boolean;

  /**  */
  salesTaxOverrideAmount: number;

  /**  */
  registration: number;

  /**  */
  registrationType: string;

  /**  */
  capAmountUpfit: number;

  /**  */
  capAmountTransportAmount: number;

  /**  */
  capAmountLeaseOrigFee: number;

  /**  */
  capAmountDocFee: number;

  /**  */
  capAmountProrate: number;

  /**  */
  capAmountPostageFee: number;

  /**  */
  capAmountEquity: number;

  /**  */
  capAmountAdditionalFeeDescription1: string;

  /**  */
  capAmountAdditionalFeeAmount1: number;

  /**  */
  capAmountAdditionalFeeDescription2: string;

  /**  */
  capAmountAdditionalFeeAmount2: number;

  /**  */
  capCostReduction: number;

  /**  */
  capCostReductionType: string;

  /**  */
  dueOnDeliverySecurityDeposit: number;

  /**  */
  dueOnDeliveryOtherDeposit: number;

  /**  */
  dueOnDeliveryUpfit: number;

  /**  */
  dueOnDeliveryTransportAmount: number;

  /**  */
  dueOnDeliveryLeaseOrigFee: number;

  /**  */
  dueOnDeliveryTms: number;

  /**  */
  dueOnDeliveryInsurance: number;

  /**  */
  dueOnDeliveryGps: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription1: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount1: number;

  /**  */
  dueOnDeliveryAdditionalFeeDescription2: string;

  /**  */
  dueOnDeliveryAdditionalFeeAmount2: number;

  /**  */
  lessTradeInEquity: number;

  /**  */
  term: number;

  /**  */
  apr: number;

  /**  */
  cashFlow: number;

  /**  */
  contractBeginDate: Date;

  /**  */
  msrp: number;

  /**  */
  invoice: number;

  /**  */
  stateTaxId: string;

  /**  */
  countyTaxId: string;

  /**  */
  cityTaxId: string;

  /**  */
  paymentCalcCode: string;

  /**  */
  calculateProrate: boolean;

  /**  */
  customerId: string;

  /**  */
  name: string;

  /**  */
  id: number;
}

export interface UpdateRequestTemplateRequest {
  /**  */
  name: string;

  /**  */
  subject: string;

  /**  */
  body: string;

  /**  */
  notifyAccountExecutive: boolean;

  /**  */
  notifyCustomerServiceRepresentative: boolean;

  /**  */
  additionalRecipients: number[];

  /**  */
  id: number;
}

export interface UpdateRoutePatternRequest {
  /**  */
  description: string;

  /**  */
  pattern: string;
}

export interface UpdateSettingRequest {
  /**  */
  value: string;
}

export interface UpdateUserPasswordRequest {
  /**  */
  confirmNewPassword: string;

  /**  */
  currentPassword: string;

  /**  */
  newPassword: string;
}

export interface UpdateUserRequest {
  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  role: string;

  /**  */
  customers: string[];

  /**  */
  claims: string[];
}

export interface UploadFileResponse {
  /**  */
  uri: string;
}

export interface UserDetailDto {
  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  role: string;

  /**  */
  id: number;

  /**  */
  customers: string[];

  /**  */
  claims: string[];

  /**  */
  phoneNumber: string;

  /**  */
  phoneNumberConfirmed: boolean;

  /**  */
  isDeleted: boolean;
}

export interface UserGetDto {
  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  role: string;

  /**  */
  id: number;
}

export interface UserOptions {
  /**  */
  customers: OptionItemDto_String[];

  /**  */
  roles: OptionItemDto_String[];

  /**  */
  permissions: string[];
}

export interface UserSummaryDto {
  /**  */
  id: number;

  /**  */
  emailAddress: string;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  role: string;

  /**  */
  emailConfirmed: boolean;

  /**  */
  phoneNumberConfirmed: boolean;

  /**  */
  isDeleted: boolean;
}

export interface VerifyPhoneNumberRequest {
  /**  */
  code: string;
}
